<template>
	<CommonPage :title="domainConfig['iamkeywords']">
		<div class="index">
			<Wrapper color="#121212">
				<div class="top">
					<h1>This Day In History</h1>
					<div class="date">
						{{ `${month} | ${day}` }}
					</div>
				</div>
			</Wrapper>

			<component
				:is="contentComponent"
				:curOne="curOne"
				:sty="{ height: '45vh' }" />
			<Wrapper color="#ffffff">
				<div class="b">
					<Ad v-if="isPC" :ads="adsensConfig.ad5" :style="{marginTop: (isPC ? '50px' : 0), textAlign: 'center'}"></Ad>
					<div
						class="pa"
						v-html="curOne?.content"></div>
					<div class="son">
						<Ad :ads="adsensConfig.ad6"></Ad>

						<div class="text">Also on This Day in History</div>
						<div class="hs">
							<div
								v-for="item in list"
								:key="item?.title"
								@click="$router.push({ name: 'detail', params: { id: item?.id } })"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.img}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.year }}</div>
								<div class="title">{{ item?.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
const _m = () => import('@/pages/components/bannerM.vue') //代码切割
const _pc = () => import('@/pages/components/bannerPC.vue') //代码切割
import '@/css/index.scss'
export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	data() {
		return {
			month: '',
			day: '',
			contentComponent: null,
			posi: '',
			quoteEng: '',
			chapters: [],
			curOne: null,
			list: [],
		}
	},
	computed: {
		description() {
			return `Understand the Terms of Use for ${this.domainConfig['IAmURL']}. Learn about user responsibilities and content guidelines.`
		},
		title() {
			return `${this.domainConfig['iamkeywords']} - Explore Daily Historical Events`
		},
	},
	created() {
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
	async activated() {
		let list = this.$store.state.list
		if (!list.length) {
			this.$router.push('/')
			return
		}
		console.log(list)
		this.curOne = list.find((e) => {
			if (e.id == this.$route.params.id) {
				return true
			}
		})
		console.log(this.$route.params.id)

		this.list = list.filter((e) => e.id != this.$route.params.id)

		const today = new Date()
		// 定义月份名称数组
		const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

		// 获取月份和日期
		const month = monthNames[today.getMonth()]
		const day = today.getDate()
		this.month = month
		this.day = day

		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	async mounted() {
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	methods: {
		//广告相关start
		async loadAdSenseScript() {
			// 其他需要在初次加载时执行的操作
			// 先检查广告是否已经加载
			if (window.adsbygoogle && window.adsbygoogle.loaded) {
				// 检查广告是否已经加载
				console.log('Adsense script already loaded.')
				this.$eventrack('adscript_loaded', 'expose')
				this.loadAdWithDelay()
				return // 如果已加载，直接返回
			}
			await this.loadScriptConditionally()
		},
		loadScriptConditionally() {
			console.log(this.adsensConfig)
			// 判断广告脚本URL是否存在
			if (!this.adsensConfig?.scriptUrl) {
				console.log('广告脚本的URL不存在,终止加载广告外链')
				this.$eventrack('no_adscript_config', 'expose')
				return
			}
			// 检查该脚本是否已经被添加
			const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`)
			if (existingScript) {
				this.$eventrack('adscript_exist', 'expose')
				console.log('脚本已存在，无需重新添加')
				return
			}

			console.log('准备插入脚本')
			const script = document.createElement('script')
			script.src = this.adsensConfig?.scriptUrl
			script.crossOrigin = 'anonymous'
			script.async = true

			const header = document.getElementsByTagName('head')[0]
			header.appendChild(script)

			this.$eventrack('adscript_add_success', 'expose')
			script.onload = this.loadAdWithDelay.bind(this) // 使用 bind 确保 this 指向正确
			console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作')
		},
		loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
			}, 500)
		},
		async displayAd() {
			await this.$nextTick()

			// 获取所有 ads 元素的 refs，过滤掉非广告的 ref
			const adsElements = Object.entries(this.$refs)
				.filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
				.flatMap(([, ref]) => ref) // 展开并获取所有元素

			if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
				console.log('Adsense script not loaded yet, delaying ad display.')
				setTimeout(this.displayAd, 500) // 延迟再次尝试
				return
			}

			console.log(adsElements) // 检查是否包含 <ins> 标签
			adsElements.forEach((ad) => {
				console.log(ad) // 输出每个广告元素
				console.log('ready to push')
				;(window.adsbygoogle = window.adsbygoogle || []).push({})
			})
		},
	},
}
</script>